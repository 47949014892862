.offer-dialog .title {
  color: #565574;
  font-weight: 600;
  font-size: 20;
  font-family: 'Poppins';
}

.offer-dialog .content {
  color: #565574;
  font-size: 16;
}

.offer-dialog .action-buttons button {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16;
  text-transform: none;
}

.offer-dialog .action-buttons .decline-button {
  color: #565574;
}

.offer-dialog .action-buttons .confirm-button {
  margin-right: 12px;
}

.offer-dialog.accept-dialog .action-buttons .confirm-button {
  color: #000;
  background: #f6c613;
}

.offer-dialog.decline-dialog .action-buttons .confirm-button {
  color: #fff;
  background: #e23629
}

.offer-dialog .highlighted-text {
  color: #000000;
}

.offer-dialog.decline-button .reason-field {
  width: 100%;
}

.offer-dialog .MuiFormLabel-root {
  font-family: 'Poppins';
}

.offer-dialog .MuiFormLabel-colorPrimary.Mui-focused {
  color: #dfb82ed5;
}

.offer-dialog .MuiInputBase-root {
  border-radius: 10px;
}

.offer-dialog .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f6c513d5 !important;
}
