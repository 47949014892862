.Offer .section-header {
  font-size: 12px;
  line-height: 18px;
  color: #b2b6cf;
  font-weight: 500;
  text-transform: uppercase;
}

.Offer .accept-button,
.Offer .decline-button {
  padding: 8px 48px 8px 24px;
  color: #000;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.Offer .accept-button {
  background: #f6c613;
  margin-right: 12px;
}

.Offer .accept-button:hover {
  background: #ffd94b;
  box-shadow: 0px 5px 20px rgba(246, 198, 19, 0.75);
}

.Offer .decline-button {
  background: #f5f5f5;
  margin-left: 12px;
}

.Offer .decline-button:hover {
  box-shadow: 0px 5px 10px #cfcfcf;
}

.Offer .loading {
  background: #ccc;
  color: #777;
  pointer-events: none;
}

.Offer .offer-dates {
  display: flex;
  flex-direction: row;
}

.Offer .offer-dates .date-section {
  margin-right: 12px;
}

.Offer .section,
.Offer .section-small {
  margin-top: 8px;
  margin-bottom: 24px;
}

.Offer .section-small {
  font-size: 12px;
}

.Offer .letter-success,
.Offer .letter-decline {
  text-align: center;
}

.Offer .action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Offer .action-buttons div,
.Offer .action-buttons .accept-button,
.Offer .action-buttons .decline-button {
  width: 100%;
}

@media (max-width: 550px) {
  .Offer .action-buttons {
    flex-flow: column;
  }
  .Offer .accept-button {
    margin-bottom: 12px;
    margin-right: 0;
  }
  .Offer .decline-button {
    margin-left: 0;
  }
}

.success-message {
  background: #8dc63f;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 0px 0px;
  color: #fff;
  width: fit-content;
  font-size: 14px;
}

.info-list {
  padding-left: 28px;
}

.info-list li {
  padding: 6px;
}

.task-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.task-list > li {
  font-weight: 600;
}

.task-list > li:before {
  content: "";
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: inline-block;
  background: url("../assets/icons/checkbox-checked.svg") no-repeat;
  background-size: contain;
  background-position: center;
  vertical-align: middle;
}

.task-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.task-list ul li {
  font-weight: 400;
  color: #777;
}

.task-list ul li:before {
  content: "";
  width: 30px;
  height: 30px;
  margin-right: 10px;
  display: inline-block;
  opacity: 0.5;
  background: url("../assets/icons/dot-information.svg") no-repeat;
  background-size: contain;
  background-position: center;
  vertical-align: middle;
}

.error-message {
  background: #ea4335;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px 0px 0px;
  color: #fff;
  width: fit-content;
  font-size: 14px;
}

.Offer .decline-reason-message {
  padding-top: 20px;
  font-size: 14px;
}

.overlay-test {
  position: absolute;
  margin-top: -20px;
  width: 592px;
}

.skeleton {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  margin: 6px 0;
  display: block;
  height: 16px;
  width: 100%;
}

.header-blob {
  width: 180px;
  height: 24px;
  margin: 16px 0;
}

.status-blob {
  width: 155px;
  border-radius: 50px;
  height: 40px;
}

.tagline-blob {
  height: 12px;
  width: 100px;
  margin: 24px 0;
}

.task-blob {
  height: 18px;
  width: 60%;
  margin: 18px 0;
}

.short {
  width: 80%;
  margin-top: 0;
}

.shorter {
  width: 50%;
  margin-top: 0;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 8px;
  border: 4px solid #f6c613;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f6c613 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
