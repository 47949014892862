.PageLayout {
  max-width: 640px;
  margin: 0 auto !important;
  padding: 24px;
}

.PageLayout .PageLayout-logo {
  margin: 20px 0;
  margin-left: 10px;
  max-width: 140px;
  width: 100%;
}

@media (max-width: 550px) {
  .PageLayout {
    padding: 12px;
  }
}
